@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap');

body{
    overflow-x: hidden;
}
.nav-container{
    display:flex;
   margin:auto;
   font-family:  'Oswald', sans-serif;
}
.navbar-toggler{
    
    background-color: #15223C !important;
  
   
}

.navbar-light .navbar-toggler-icon{
    background-image: url('../img/navd.png');
}
.navbar{
    background-color: #15223C !important;
    margin-top: 0;
    height:70px;
    font-size: 17px;
        border-bottom: 5px solid #15223C;
        box-shadow:0px 10px 10px rgb(0 0 0 / 60%);
}
.nav-link{
    color:aliceblue !important;
}
.nav-link:hover {
   background-color: white;
   color: #15223C !important;
   border-radius: 5px;
}
.navbar-collapse{
    background-color: #15223C !important;
    text-align: center;
    width:400px;
    
    
}
button.navbar-toggler{
    display: flex;
    justify-content: center;
    margin: auto;
    margin-bottom: 10px;
    border-bottom: 5px solid #f2f2f2;
    
}