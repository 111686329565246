@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Oswald:wght@200&family=Ovo&display=swap'); 

b{
    color: #15223C;
}
.concontain{
    font-family: 'Ovo', serif;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
   
    justify-content: center;
    background-color: #f2f2f2;
    padding-bottom: 10px;

}
#cheader{
    width:100%;
}
.crow{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 40px;
   
    justify-content: center;
    gap:60px;
    background-color: white;

}
.timgc{
    max-width: 800px;
    height: 600px;
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 20px;
    
    
}
#timg{
        width: 100%;
        height:100%;
        border: 15px solid white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);

        
}
.ctext{
    max-width: 700px;
    margin-top: auto;
    margin-bottom: auto;
    
}
#subtc{
    margin-bottom: 10px !important;
}
#subc{
    margin-top: 20px;
}
#back{
    width: 100%;
   
}
.clientsContact{
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 1000px;
    margin: auto;
    padding: 40px;
        margin-bottom: 40px;
   
}
#clientHead{
   margin-bottom: 20px !important;
}
#subtcl{
    margin-bottom: 30px !important;
}
#hrc{
   background-color:#15223C !important;
   height:15px;
   margin-bottom: 40px;
}
#hrct{
    background-color:#15223C !important;
    height:15px;
    
 }
 @media screen and (max-width:655px) {
    .timgc{
   
        height: 400px;
    
        
        
    }
 }