@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Oswald:wght@200&family=Ovo&display=swap');


#foot{
    color:white !important;
    background-color: #15223C !important;
    
}
#footcol{
    display:flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 600px;
    margin-top:15px;
    text-align: center;
}
#social {
  

    color: #15223C;
    border-top:5px solid #15223C;
    border-left:5px solid #15223C;
    border-right:5px solid #15223C;
   background-color: #f2f2f2 !important;

}
#colOne{
    background-color: white;
    color: #15223C;
    border: 5px solid #15223C;
}
#colOne img{
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top:15px;
    max-width:100%;

}
#colOne p{
    font-family: 'Oswald';
    font-size: 15px;
    font-weight: 600px;
    margin-top:15px;
    text-align: center;
}
#foota a:hover{
    border-bottom: 4px solid white;
}
#foota a::selection{
    border-bottom: 4px solid #f2f2f2;
}
@media screen and (max-width:768px) {
#footcol{
    margin: auto;
}
}