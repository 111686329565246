@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200&family=Ovo&display=swap');
body{
    height:fit-content;
    overflow-x: hidden;
   
}


/*Home Two CSS*/
 
.containTwo{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
  padding: 40px;
    font-weight: 350;
    width:100%;
   min-height: 100%;
   max-height: fit-content;
    gap:20px;
   
}
.rowOne{

    height:fit-content;
    display: flex;
    flex-direction: column;
    justify-content:center;
   margin-left: auto;
    margin-right: auto;
        /*border: solid 1px red;*/
        max-width: 800px;
      min-height: 100%;
}
#typed{
  color: #15223C;
}

#pOne{
    font-size: 25px;
    color:#15223C;
    margin-bottom: 20px;
}
.rowOne p{
    font-size: 18px;
    width:100%;
    margin-bottom: 20px !important;
   
}
.rowOne h2{
    text-align: center;
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    
}


.rowTwo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: auto;
  margin-bottom: auto;
    border: solid 5px #15223C;
    width: clamp(200px,500px,600px);
    height:100%;
    margin-left:auto;
    margin-right: auto;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
    /*animation: loading 1.3s linear infinite;*/
}
.containerThree {

    width: 100%;
    height:100%;
    position: relative;
    font-family:  'Oswald', sans-serif !important;

  }
  
  #contact input[type="text"],
  #contact input[type="email"],
  #contact input[type="tel"],
  #contact input[type="url"],
  #contact textarea,
  #contact button[type="submit"] {
    font-size: 400 12px/16px;
  }
  
  #contact {
    background: #F9F9F9;
    height: 100%;
    padding: 25px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
    
  }
  
  #contact h3 {
    display: block;
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 10px;
  }
  
  #contact h4 {
    margin: 5px 0 15px;
    display: block;
    font-size: 13px;
    font-weight: 400;
  }
  
  fieldset {
    border: medium none !important;
    margin: 0 0 10px;
    min-width: 100%;
    padding: 0;
    width: 100%;
  }
  
  #contact input[type="text"],
  #contact input[type="email"],
  #contact input[type="tel"],
  #contact input[type="url"],
  #contact textarea {
    width: 100%;
    border: 1px solid #ccc;
    background: #FFF;
    margin: 0 0 5px;
    padding: 10px;
  }
  
  #contact input[type="text"]:hover,
  #contact input[type="email"]:hover,
  #contact input[type="tel"]:hover,
  #contact input[type="url"]:hover,
  #contact textarea:hover {
    -webkit-transition: border-color 0.3s ease-in-out;
    -moz-transition: border-color 0.3s ease-in-out;
    transition: border-color 0.3s ease-in-out;
    border: 1px solid #aaa;
  }
  
  #contact textarea {
    height: 100px;
    max-width: 100%;
    resize: none;
  }
  
  #contact button[type="submit"] {
    cursor: pointer;
    width: 100%;
    border: none;
    background-color: #15223C;
    color: #FFF;
    margin: 0 0 5px;
    padding: 10px;
    font-size: 15px;
  }
  
  #contact button[type="submit"]:hover {
    background: white;
    border: 5px solid #15223C !important;
    color:#15223C;
    -webkit-transition: background 0.3s ease-in-out;
    -moz-transition: background 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out;
  }
  
  #contact button[type="submit"]:active {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
  }
  
  .copyright {
    text-align: center;
  }
  
  #contact input:focus,
  #contact textarea:focus {
    outline: 0;
    border: 1px solid #aaa;
  }
  
  ::-webkit-input-placeholder {
    color: #888;
  }
  
  :-moz-placeholder {
    color: #888;
  }
  
  ::-moz-placeholder {
    color: #888;
  }
  
  :-ms-input-placeholder {
    color: #888;
  }
/*
@keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  */


.space{
    height:100px;
    
}
#bt{
  margin-left: auto;
  position: relative;
    margin-right: auto;
    margin-top: 2%;
    border:5px solid #15223C !important;
    width:30%;
    border-radius: 1px; 
    color:aliceblue !important;
    background-color: #15223C !important;
}
.btnh{

    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    border:5px solid #15223C !important;
    width:fit-content !important;
    border-radius: 1px; 
    color:aliceblue !important;
    background-color: #15223C !important;
    text-align: center;
    
  
}
.btnh:hover{
    background-color: white !important;
   border: 5px solid #15223C !important;
   color: #15223C !important;
}

#nextc{
    color:#15223C !important;
}

/*Home Three CSS*/
.containFour{
    display: flex;
    flex-direction: column;
    width:100%;
    padding:25px;
    height:fit-content;
    background-color: #f2f2f2;
    gap: 15px;
    overflow-x: hidden;
}
.pth{
  display: flex;
  flex-direction: column;
  width:100%;
  padding:15px;
  background-color: #15223C;
  color: white !important;
}
.head{
  text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top:2%;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 36px;
    color:#15223C;
}
.rBox{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 20px;
  
}
.reviewBox{
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
    width:100%;
    max-width: 600px;
    min-height:618px;
    margin-left: 10%;
    margin-right: 2%;
    padding: 40px;
    background-color: white;
    box-shadow:0px 20px 40px rgb(0 0 0 / 60%);
    margin-bottom: 40px;
    margin-top:40px ;
 
}
#review{
  font-family: 'Ovo';
  font-size: 21.3px;

  font-style: italic;
  
}
#quoteOP{
  width:35px;
}
#quoteCL{
  position: relative;
  width:35px;
  margin-left: 95%;
}
.rinfo{
  
    text-align:start;
    width:100%;
    height: 100%;
    max-width: 480px;
   
   font-family: 'Ovo';
   font-size: 15px;
   padding:40px;
   margin-top: 40px;

}
.subtitle{
  line-height: 20px;
  margin-top: 20px;
}
#bigt{
  font-family: 'Ovo';
  color:#15223C;
  font-size: 18px;
  font-weight: 600;
}
#smallt{
  font-size: 15px;
}

#imgHolder{
  margin-bottom: 30px;
  max-width:200px;
 
}
.spaceTwo{
  height: 200px;
}
.slide{
  background-color:#F2F2F2;
}
@media screen and (max-width:1233px) {
  .containFour{
    max-height:900px ;
    overflow-x: hidden;
  }
  .rbox{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
   height:100%;
    }
  .reviewBox{
  
    margin: auto;
  }
  .rinfo{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    margin: auto;
   
  }
  .rinfo img{
    margin-left: auto;
    margin-right: auto;
  }

}