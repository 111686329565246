/*Top/*/
.partOne{
    display:flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #f2f2f2;

}
.bgimga{
    text-align: left;
    min-height:100%;
    background-color: #f2f2f2;
    max-width: 720px;
    
}
.bgimga img{
    border-bottom: 5px solid #15223C;
    width:100%;
    min-height: 100%;
}
.rowtwo{
  display:flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    padding:20px;
    max-width: 50%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top:1.5%;
    padding-bottom: 10px;
    padding-top: 10px;

}
.ri{
  display: flex;
  justify-content: center;
  max-width: 100%;
  height:100%;
  overflow: hidden;
  padding: 50px;
}
/*
#itwo{
  max-width:35%;
}*/
#subtitle{
     font-family: 'Oswald';
    font-size: 30px;
    margin-bottom: 0px;
    margin: auto;
    text-align: center;
    color: #15223C;
}
#pone{
    width:100%;
    margin: auto;
    font-size: 20px;
    color: black;
    padding: 10px;
}

/*Hover Cards*/
:root{
    --background-dark: #2d3548;
    --text-light: rgba(255,255,255,0.6);
    --text-lighter: rgba(255,255,255,0.9);
    --spacing-s: 8px;
    --spacing-m: 16px;
    --spacing-l: 24px;
    --spacing-xl: 32px;
    --spacing-xxl: 64px;
    --width-container: 2000px;
  }
  
  .contain{
    border: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
  }
  
  .hero-section{
    align-items: flex-start;
    background-color: #15223C;
    display: flex;

    min-height: 100%;
    justify-content: center;
    padding: var(--spacing-xxl) var(--spacing-l);
  }
  
  .card-grid{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: var(--spacing-l);
    grid-row-gap: var(--spacing-l);
    max-width: var(--width-container);
    width: 100%;
  }
  
  @media(min-width: 540px){
    .card-grid{
      grid-template-columns: repeat(2, 1fr); 
    }
  }
  
  @media(min-width: 960px){
    .card-grid{
      grid-template-columns: repeat(4, 1fr); 
    }
  }
  
  .card{
    list-style: none;
    position: relative;
    background-color: #15223C;
  }
  
  .card:before{
    content: '';
    display: block;
    padding-bottom: 150%;
    width: 100%;
    border-radius: var(--spacing-l);
  }
  
  .card__background{
    background-size: cover;
    background-position: top;
    border-radius: var(--spacing-l);
    bottom: 0;
    filter: brightness(0.75) saturate(1.2) contrast(0.85);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: center;
    transform: scale(1) translateZ(0);
    transition: 
      filter 200ms linear,
      transform 200ms linear;
  }
  
  .card:hover .card__background{
    transform: scale(1.05) translateZ(0);
  }
  
  .card-grid:hover > .card:not(:hover) .card__background{
    filter: brightness(0.5) saturate(0) contrast(1.2) blur(20px);
  }

  .card p{
    display: none;
    font-size: 14.5px;
    color:white;
  }
  .card:hover p{
    display: block;
  }
  .card::selection p{
    display: block;

  }
  
  .card__content{
    left: 0;
    padding: var(--spacing-l);
    position: absolute;
    top: 0;
  }
  
  .card__category{
    color: var(--text-light);
    font-size: 0.9rem;
    margin-bottom: var(--spacing-s);
    text-transform: uppercase;
  }
  
  .card__heading{
    color: var(--text-lighter);
    font-size: 1.9rem;
    text-shadow: 2px 2px 20px rgba(0,0,0,0.2);
    line-height: 1.4;
    word-spacing: 100vw;
  }







@media screen and (max-width:1404px) {
    .rowtwo{
        max-width: 40%;
        margin: auto;
        padding: 5px;
    }
}
@media screen and (max-width:1171px) {
    .rowtwo{
        max-width: 60%;
        margin: auto;
        padding: 5px;
    }
    .bgimga{
        max-width: 40%;
    }
}

@media screen and (max-width:939px) {
    .rowtwo{
        max-width: 90%;
        margin: auto;
        padding: 5px;
    }
    .bgimga{
        min-width: 100%;
      
    }
    #subtitle{
        font-size: 20px;
    }
    #pone{
        font-size: 13px;
    }
}
/*Codes*/
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Oswald:wght@200&family=Ovo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Oswald:wght@200&family=Ovo&display=swap'); 


#heading{
  font-size: 20px;
  font-family: 'Ovo', serif;
  font-weight: 700;
  color: #15223C;
}
#content{
  font-size: 16px;
  font-family: 'Ovo', serif;
}

.Codes{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width:100%;
 
}

.col{
 
  max-width: 702px;
  min-height: 100%;
}
.col img{
  width:100%;
  height:100%;
  
}
.colOnea{
  flex-direction: column;
  padding:30px;
  flex-wrap: wrap;
  max-width: 735px;
  min-height: 100%;

}

.colTwoa{
  flex-wrap: wrap;
  max-width: 420px;
  flex-direction: column;
  padding:30px;
  min-height: 100%;

}

#codeOne{
    background-color: #2d3548;
    color:white;
    border: 2px solid black;
    width:fit-content;
    padding:20px;
    text-align: center;
    
}

#codeTwo{
  background-color: #2d3548;
  color:white;
  border: 2px solid black;
  width:fit-content;
  padding:20px;
  text-align: center;
  margin-top: 20px;
}

@media screen and (max-width:1110px) {
.Codes{
  max-width: 100%;
  display:flex;
flex-wrap: wrap;
flex-direction: column;
  justify-content: center;
margin: auto;


}
  .col{
    max-width: 100%;
    display:flex;
  flex-wrap: wrap;
  flex-direction: column;
    justify-content: center;
   
  }
  .col img{
    width:400px;
    height:300px;
    margin: auto;
  }
  .colOnea{
    padding-bottom: 0px;
    margin: auto;
  }
  .colTwoa{
    max-width: 320px;
    margin: auto;

  }

}