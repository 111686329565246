@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;500&display=swap');

#top{
    font-family:  'Oswald', sans-serif;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    color:#15223C;
  
  padding-top: 10px;
  padding-bottom: 10px;
}
.logo{
   padding-right:20px;
   
}
.logo img{
    width:100%;
    height:82%;
}
.quoteAdd{
   margin: auto;
   justify-content: center;
   text-align: center;
   
}
.callAdd{
    text-align: center;
    padding:10px;
    justify-content: center;
    margin:auto;
  
}
.callAdd p{
    font-size: 20px;
    margin: auto;
}
.btn{
    position: relative;
 
    border:5px solid #15223C !important;
    width:90%;
    border-radius: 0px; 
    color:aliceblue !important;
    background-color: #15223C !important;
    
  
}
.btn:hover{
    background-color: white !important;
   border: 5px solid #15223C !important;
   color: #15223C !important;
}
@media screen and (max-width:1200px) {
    #top{

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;

    }
    .logo{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
    }
    .logo img{
        max-width: 250px;
        margin:auto;
        margin-bottom: 20px;
    }
}