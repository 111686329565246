body{
    overflow-x: hidden;
}
#o{
    --i:1;
}
.two{
    --i:2;
}
.three{
    --i:3;
}
.four{
    --i:4;
}
.five{
    --i:5;
}
.six{
    --i:6;
}


.sbg{
    position: relative;
	display:flex;
    flex-wrap: wrap;
    justify-content: center;
	height:280px;
	max-width:100%;
   
    
}
.slider{
		display:flex;
       padding:10px;
	position:absolute;

	margin-top:17%;
	width:300px;
	height:300px;
	transform-style:preserve-3d;
	animation:animate 20s linear infinite;
}
@keyframes animate{
	0%{
		transform: perspective(1000px) rotateY(0deg);
	}
	100%{
		transform:perspective(1000px) rotateY(360deg);
	}
		
	
}
.slider span{
	display:flex;
	position:absolute;


	top:0;
	left:0;
	width:60%;
	height:100%;
	transform-origin:center;
	transform-style:preserve-3d;
	transform: rotateY(calc(var(--i)*60deg)) translateZ(400px);

}

.slider span img{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	object-fit:contain;
}

#text{
    position: absolute;
    margin-top:6%;
    font-size: 3vw;
}
