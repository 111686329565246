@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Oswald:wght@200&family=Ovo&display=swap'); 
.scontain{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: fit-content;
    width: 100%;
}
#header{
    width:100%;
    max-height: 100%;
}
.one{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
    max-width: 100%;
    overflow-x: hidden;
    gap: 20px;
}
#bg{
max-width:100%;
object-fit: cover;
float: right;
    
}
.sboxOne{
    max-width: 540px;
    font-family: 'Ovo', serif;
    padding: 40px;
    margin-bottom: auto;
    margin-top: auto;
}
#stit{
    font-size: 36px;
    line-height: 35px;
}
.sboxOne p{
    font-size: 16px;
}
.servlist{
    display: flex;
    justify-content: center;
    flex-direction: row;
    font-family: 'Ovo', serif;
    padding: 20px 70px;
    gap:23px;
    background-color: #f2f2f2;

}
#subtit{
    font-size: 18px;
  font-weight: 700;
  color: #15223C;
  margin-bottom: 0px;
}
#conttext{
    font-size: 17px;
}
@media screen and (max-width:854px) {
    .servlist{
        display: flex;
        justify-content: center;
        flex-direction: column;
    
    }
}